import React from "react";
import Layout from "../components/layout/layout";
import SEOComponent from "../components/seo";
import GenerateDemoChatLogin from "../components/generatedemochat/GenerateDemoChatLogin";

const DemoChat = () => (
    <Layout mode={true} sticky_footer={false}>
        <SEOComponent title="Amber by inFeedo | Chat Generator" />
        <GenerateDemoChatLogin />
    </Layout>
)

export default DemoChat