import React, { Component } from 'react';
import config from '../../../config';
import google from '../../../static/images/google.svg'
import infeedo_logo from '../../../static/images/infeedo.svg';

export default class GenerateDemoChatLogin extends Component {

    OAuth = (which) => {
        window.location = `${config.url}auth/${which}?module=generate_demo_chat`
    }

    render() {
        return (
            <div className="columns pd-25" id="demo-chat">
                <div className="column is-4 mar-auto pd-bottom-100">
                    <figure className="app-logo">
                        <img src={infeedo_logo} width="130px" alt="amber logo" />
                    </figure>
                    <h1 className="login-heading has-text-centered">Generate Demo Chat Login</h1>
                    <div className="oauth-login pd-bottom-100">
                        <button className="google-button button has-text-weight-semibold" onClick={this.OAuth.bind(this, 'google')}><img className="mg-right-10" src={google} title="google-icon-svg" alt="google-logo" />Continue with Google</button>
                    </div>
                </div>
            </div>
        )
    }
}
